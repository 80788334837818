// OutboxMessage.js
import { connect } from 'react-redux';

import getMeData from '../selector/getMeData.js';
import getUserData from '../selector/getUserData.js';
import getMessageData from '../selector/getMessageData.js';
import getMessageDataWithVariable, {
  VariableName,
} from '../selector/getMessageDataWithVariable.js';
import fetchMediaMenifestOfMessage from '../action/fetchMediaMenifestOfMessage.js';
import fetchUser from '../action/fetchUser.js';
import OutboxMessage from '../component/OutboxMessage.jsx';
import getMessageThumbnail from '../selector/getMessageThumbnail.js';
import getAssetData from '../selector/getAssetData.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import getUsername from '../selector/getUsername.js';
import fetchDrmLicenseAvailability from '../action/fetchDrmLicenseAvailability.js';

import { getUserPicture, MediaAssetFormat } from '../resource/getMediaAsset.js';

import { PREVIEW_PRESET } from '../RemoteConfigKeys.js';

const mapStateToProps = (state, ownProps) => {
  const { messageId } = ownProps;
  const userId = getMessageData(state, messageId, 'senderId');
  const unlockPrice = getMessageData(state, messageId, 'unlockPrice');
  const assetIds = getMessageData(state, messageId, 'assetIds');
  const assetId = assetIds?.[0];
  const meId = getMeData(state, 'id');
  const meUsername = getMeData(state, 'username');
  const hasVerified = getRemoteConfigData(state, PREVIEW_PRESET) === 'clear';

  return {
    userAvatarSrc: getUserPicture({
      userId,
      meId,
      size: 128,
      format: MediaAssetFormat.JPG,
    }).href,
    isAuthed: !!meId,
    mediaType: assetId
      ? getAssetData(state, [assetId], 'contentType')
      : getMessageData(state, messageId, 'mediaType'),
    originalTitle: getMessageDataWithVariable(
      state,
      messageId,
      'originalTitle',
      `${VariableName.USERNAME}:${meUsername}`
    ),
    originalCaptionText: getMessageDataWithVariable(
      state,
      messageId,
      'originalCaptionText',
      `${VariableName.USERNAME}:${meUsername}`
    ),
    expiresAtUnix: getMessageData(state, messageId, 'expiresAtUnix'),
    unlockPrice,
    thumbnail: getMessageThumbnail(
      state,
      messageId,
      '',
      '256x256',
      'jpg',
      hasVerified ? (unlockPrice ? '' : 'clear') : 'blurred'
    ),
    userId,
    username: getUserData(state, userId, 'username'),
    displayedUsername: getUsername(state, userId, 'displayNameFirst'),
    canViewMedia: getMessageData(state, messageId, 'canViewMedia'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchMediaMenifestOfMessage: ({ messageId }) =>
      dispatch(fetchMediaMenifestOfMessage({ id: messageId })),
    fetchDrmLicenseAvailability: ({ messageId, keySystem }) =>
      dispatch(fetchDrmLicenseAvailability({ messageId, keySystem })),
    fetchUser: ({ userId }) => dispatch(fetchUser({ id: userId })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OutboxMessage);
