// getUsername.js
'use strict';
import { FEATURE_DISPLAY_NAME } from '../RemoteConfigKeys.js';

import createCachedSelector from '../resource/createCachedSelector.js';

import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import getUserData from '../selector/getUserData.js';
import getMeData from '../selector/getMeData.js';

/**
 * Select username by user id
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} userId - user id.
 * @param {'displayName'|'username'|'displayNameFirst'} key - data key.
 * @return {string|undefined} The selected username.
 */
const getUsername = createCachedSelector(
  state => getRemoteConfigData(state, FEATURE_DISPLAY_NAME) === 1,
  (state, userId) => getData(state, userId, 'username'),
  (state, userId) => getData(state, userId, 'displayName'),
  (state, userId, key) => key,
  (isDisplayNameEnabled, paramUsername, displayName, key) => {
    const username = paramUsername ? `@${paramUsername}` : undefined;
    switch (key) {
      case 'displayName': {
        return isDisplayNameEnabled ? displayName : undefined;
      }
      case 'displayNameFirst': {
        return isDisplayNameEnabled && displayName ? displayName : username;
      }
      case 'username':
      default: {
        return username;
      }
    }
  }
)((state, userId, key) => `${userId}:${key}`);

/**
 * Select user or me data by user id
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} userId - user id.
 * @param {string} dataKey - data key.
 * @return {string|undefined} The selected username.
 */
const getData = createCachedSelector(
  state => getMeData(state, 'id'),
  (state, userId) => userId,
  (state, userId, dataKey) => getMeData(state, dataKey),
  (state, userId, dataKey) => getUserData(state, userId, dataKey),
  (meId, userId, meResult, userResult) => {
    if (meId != null && meId === userId) {
      return meResult;
    }
    return userResult;
  }
)((state, userId, dataKey) => `${userId}:${dataKey}`);

export default getUsername;
