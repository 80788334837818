// OutboxMessage.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { differenceInHours, differenceInMinutes, fromUnixTime } from 'date-fns';
import Observer from '@researchgate/react-intersection-observer';

import DecryptionWrapper from '../container/DecryptionWrapper.js';
import WithMessageMetaData from '../container/WithMessageMetaData.js';
import UserStatusIndicator from '../container/UserStatusIndicator.js';
import UserAvatar from '../container/UserAvatar.js';

import HighlightSubstring from './HighlightSubstring.jsx';

import StatefulImage from './StatefulImage.jsx';
import TextEllipsis from '../style/TextEllipsis.js';
import Spinning from '../style/Spinning.js';
import { textColor, color } from '../style/variables.js';
import media from '../style/media.js';

import LoadingImage from '../../img/loading.png';
import PhotoIcon from '../../img/photo_old.svg';
import LikeIconSrc from '../../img/ic_like_xs.svg';
import NewImageIcon from '../../img/photo_new.svg';
import NewVideoIcon from '../../img/ic_video.svg';
import DiamondImageSrc from '../../img/point_2.svg';
import TimerIcon from '../../img/ic_timer.svg';
import ViewersIconSrc from '../../img/ic_streaming_view_xs.svg';
import UnLockIconSrc from '../../img/ic_unlock_xs.svg';
import PinnedGreyIconSource from '../../img/ic_pin_grey.svg';

import formatNumber from '../resource/formatNumber.js';
import { getKeySystem } from '../resource/getDrmInfo.js';
import { TranslationNamespace } from '../resource/translationNamespace.js';

const THUMBNAIL_SIZE = 120;
const MOBILE_THUMBNAIL_SIZE = 84;

export class OutboxMessage extends React.PureComponent {
  state = {
    isIntersecting: false,
  };

  handleIntersection = ({ isIntersecting }, unobserve) => {
    this.setState({ isIntersecting });
    if (isIntersecting) {
      unobserve();
    }
  };

  fetchUser = () => {
    const { username, userId, fetchUser } = this.props;
    const { isIntersecting } = this.state;

    if (userId && isIntersecting && !username) {
      return fetchUser({ userId });
    }
  };

  fetchDrmLicenseAvailability = () => {
    const { messageId, canViewMedia, fetchDrmLicenseAvailability } = this.props;
    const { isIntersecting } = this.state;

    if (!messageId || canViewMedia || !isIntersecting) return;

    fetchDrmLicenseAvailability({
      messageId,
      keySystem: getKeySystem(),
    });
  };

  getMediaTypeIconSrc = () => {
    const { mediaType } = this.props;
    if (mediaType && 0 === mediaType.search('image')) {
      return NewImageIcon;
    }
    if (mediaType && 0 === mediaType.search('video')) {
      return NewVideoIcon;
    }
  };

  componentDidMount() {
    const { fetchMediaMenifestOfMessage, messageId } = this.props;
    fetchMediaMenifestOfMessage({ messageId });
    this.fetchDrmLicenseAvailability();
    this.fetchUser();
  }

  componentDidUpdate(prevProps, prevState) {
    const { messageId, userId, username, canViewMedia } = this.props;
    const { isIntersecting } = this.state;

    if (
      messageId !== prevProps.messageId ||
      canViewMedia !== prevProps.canViewMedia ||
      isIntersecting !== prevState.isIntersecting
    ) {
      this.fetchDrmLicenseAvailability();
    }

    if (
      userId !== prevProps.userId ||
      username !== prevProps.username ||
      isIntersecting !== prevState.isIntersecting
    ) {
      this.fetchUser();
    }
  }

  renderRemainTime() {
    const { expiresAtUnix, t } = this.props;
    if (!expiresAtUnix) {
      return null;
    }

    const now = Date.now();
    const remainDate = fromUnixTime(expiresAtUnix);
    const remainMinutes = differenceInMinutes(remainDate, now);

    const remainTime =
      remainMinutes < 0
        ? null
        : 60 > remainMinutes
          ? t('media_timeline_minutes_left', {
              ...[remainMinutes],
              ns: TranslationNamespace.FEED,
            })
          : t('media_timeline_hours_left', {
              ...[differenceInHours(remainDate, now)],
              ns: TranslationNamespace.FEED,
            });
    return (
      <>
        <RemainTimeClock src={TimerIcon} />
        <RemainTime>
          <span>{remainTime}</span>
        </RemainTime>
      </>
    );
  }

  renderUnlocks = ({ unlocks }) => {
    return (
      <Metadata>
        <ViewersIcon
          src={UnLockIconSrc}
          alt="Unlock count"
          width={16}
          height={16}
        />
        <ViewCount>{unlocks ? formatNumber(unlocks) : '-'}</ViewCount>
      </Metadata>
    );
  };
  renderViewCount = ({ viewCount }) => {
    return (
      <Metadata>
        <ViewersIcon
          src={ViewersIconSrc}
          alt="view count"
          width={16}
          height={16}
        />
        <ViewCount>{viewCount ? formatNumber(viewCount) : '-'}</ViewCount>
      </Metadata>
    );
  };
  renderLikePercentage = ({ likePercentage }) => {
    return (
      <Metadata>
        <MetaIcon src={LikeIconSrc} />
        <MetaValue>
          {typeof likePercentage === 'number' ? `${likePercentage}%` : '-'}
        </MetaValue>
      </Metadata>
    );
  };

  renderAvatar = () => {
    const { userId } = this.props;
    return (
      <>
        <NonDesktopAvatar>
          <UserAvatar id={userId} size={16} />
        </NonDesktopAvatar>
        <DesktopAvatar>
          <UserAvatar id={userId} size={24} />
        </DesktopAvatar>
      </>
    );
  };

  render() {
    const {
      messageId,
      thumbnail,
      unlockPrice,
      isAuthed,
      isMessagePack,
      canViewMedia,
      isPinned,
      userAvatarSrc,
      highlightText,
      userId,
      username,
      displayedUsername,
      shouldShowUsername,
      shouldShowAvatar,
      t,
      originalTitle,
      originalCaptionText,
    } = this.props;
    const shouldShowUnlockPrice =
      isAuthed && null !== unlockPrice && !canViewMedia && unlockPrice > 0;

    const shownText = originalTitle || originalCaptionText || '-';
    return (
      <Observer onChange={this.handleIntersection}>
        <StyledOutboxMessage isPinned={isPinned}>
          <MessageAvatarWrapper>
            <MessageAvatar>
              <StatefulImage>
                <StatefulWrapper data-key="loading">
                  <Loader src={LoadingImage} />
                </StatefulWrapper>
                <StatefulWrapper data-key="error">
                  <img src={PhotoIcon} alt="" />
                </StatefulWrapper>
                <DecryptionWrapper
                  resourceUrl={isMessagePack ? userAvatarSrc : thumbnail}
                  data-key="target"
                >
                  <AvatarImage
                    alt={`${username} : ${shownText}`}
                    src={isMessagePack ? userAvatarSrc : thumbnail}
                  />
                </DecryptionWrapper>
              </StatefulImage>
            </MessageAvatar>
          </MessageAvatarWrapper>
          {isPinned && (
            <Pinned>
              <PinIcon src={PinnedGreyIconSource} />
              <PinText>
                {t('pinned_post', {
                  ns: TranslationNamespace.FEED,
                })}
              </PinText>
            </Pinned>
          )}
          <CaptionText isPinned={isPinned}>
            {highlightText ? (
              <HighlightSubstring substring={highlightText}>
                {shownText}
              </HighlightSubstring>
            ) : (
              shownText
            )}
          </CaptionText>
          <DetailInfo>
            <MediaTypeIcon src={this.getMediaTypeIconSrc()} />
            {this.renderRemainTime()}
            {(shouldShowUsername || shouldShowAvatar) && (
              <UserInfo>
                {shouldShowAvatar && this.renderAvatar()}
                {shouldShowUsername && (
                  <>
                    <Username>{displayedUsername}</Username>
                    <UserStatusIndicatorWrapper>
                      <UserStatusIndicator
                        userId={userId}
                        shouldShowLiveStream
                        livestreamSize={12}
                        shouldShowOnline
                      />
                    </UserStatusIndicatorWrapper>
                  </>
                )}
              </UserInfo>
            )}
          </DetailInfo>
          <Metadatas>
            <WithMessageMetaData messageId={messageId}>
              {({ unlocks, viewCount, likePercentage }) => {
                return (
                  <>
                    {shouldShowUnlockPrice
                      ? this.renderUnlocks({ unlocks })
                      : this.renderViewCount({ viewCount })}
                    {this.renderLikePercentage({ likePercentage })}
                  </>
                );
              }}
            </WithMessageMetaData>
          </Metadatas>
          {shouldShowUnlockPrice && (
            <UnlockPrice>
              <img src={DiamondImageSrc} alt="" />
              <UnlockPriceNumber>{unlockPrice}</UnlockPriceNumber>
            </UnlockPrice>
          )}
        </StyledOutboxMessage>
      </Observer>
    );
  }
}

OutboxMessage.propTypes = {
  isAuthed: PropTypes.bool,
  isMessagePack: PropTypes.bool,
  canViewMedia: PropTypes.bool,
  isPinned: PropTypes.bool,
  userAvatarSrc: PropTypes.string,
  mediaType: PropTypes.string,
  messageId: PropTypes.string,
  thumbnail: PropTypes.string,
  originalTitle: PropTypes.string,
  originalCaptionText: PropTypes.string,
  unlockPrice: PropTypes.number,
  expiresAtUnix: PropTypes.number,
  fetchMediaMenifestOfMessage: PropTypes.func,
  fetchDrmLicenseAvailability: PropTypes.func,
  fetchUser: PropTypes.func,
  t: PropTypes.func.isRequired,
  highlightText: PropTypes.string,
  userId: PropTypes.string,
  username: PropTypes.string,
  displayedUsername: PropTypes.string,
  shouldShowUsername: PropTypes.bool,
  shouldShowAvatar: PropTypes.bool,
};

OutboxMessage.defaultProps = {
  isAuthed: false,
  isMessagePack: false,
  canViewMedia: false,
  isPinned: false,
  userAvatarSrc: '',
  mediaType: '',
  messageId: '',
  thumbnail: '',
  originalTitle: '',
  originalCaptionText: '',
  unlockPrice: null,
  expiresAtUnix: 0,
  fetchMediaMenifestOfMessage: () => null,
  fetchDrmLicenseAvailability: () => null,
  fetchUser: () => null,
  highlightText: '',
  userId: '',
  username: '',
  displayedUsername: '',
  shouldShowUsername: false,
  shouldShowAvatar: false,
};

const StyledOutboxMessage = styled.section`
  display: grid;
  grid-template-columns: ${THUMBNAIL_SIZE}px 1fr auto;
  grid-template-rows: ${({ isPinned }) => (isPinned ? '30px 30px' : '60px')} 24px 28px;
  grid-template-areas:
    ${({ isPinned }) =>
      isPinned ? "'messageAvatar pinnedText pinnedText'" : ''}
    'messageAvatar captionText captionText'
    'messageAvatar detailInfo detailInfo'
    'messageAvatar metadata unlockPrice';
  grid-column-gap: 12px;
  grid-row-gap: 4px;
  padding-top: 8px;
  padding-bottom: 8px;
  ${media.mobile`
    grid-template-columns: ${MOBILE_THUMBNAIL_SIZE}px 1fr auto;
    grid-template-rows: ${({ isPinned }) =>
      isPinned ? '24px 20px' : '40px'} 16px 20px;
  `};
`;

const MessageAvatarWrapper = styled.div`
  position: relative;
  grid-area: messageAvatar;
  display: flex;
  align-items: center;
`;

const MessageAvatar = styled.div`
  border-radius: 8px;
  width: ${THUMBNAIL_SIZE}px;
  height: ${THUMBNAIL_SIZE}px;
  overflow: hidden;
  ${media.mobile`
    width: ${MOBILE_THUMBNAIL_SIZE}px;
    height: ${MOBILE_THUMBNAIL_SIZE}px;
  `};
`;

const StatefulWrapper = styled.div`
  height: 100%;
  img {
    height: 100%;
  }
`;

const Loader = styled.img`
  animation: 6s infinite ${Spinning};
`;

const Pinned = styled.div`
  grid-area: pinnedText;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const PinIcon = styled.img`
  margin-right: 4px;
  width: 20px;
  height: 20px;
  object-fit: contain;
  ${media.mobile`
    width: 16px;
    height: 16px;
  `};
`;

const PinText = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4;
  color: ${color.neutral[55]};
  ${media.mobile`
    font-size: 12px;
  `};
`;

const CaptionText = styled.h3`
  display: block;
  grid-area: captionText;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  overflow: hidden;
  display: -webkit-box;
  white-space: pre-line;
  -webkit-line-clamp: ${({ isPinned }) => (isPinned ? 1 : 2)};
  -webkit-box-orient: vertical;
  margin: 0;
  ${media.mobile`
    font-size: 14px;
    line-height: 1.4;
  `};
`;

const DetailInfo = styled.div`
  display: flex;
  align-items: center;
  grid-area: detailInfo;
  > * {
    display: inline-block;
    vertical-align: middle;
  }
  ::after {
    content: '';
    width: 0;
    height: 100%;
    display: inline-block;
    position: relative;
    vertical-align: middle;
  }
`;

const MediaTypeIcon = styled.img`
  margin-right: 4px;
  height: 24px;
  width: 24px;
  object-fit: contain;
  ${media.mobile`
    height: 16px;
    width: 16px;
  `};
`;

const RemainTime = styled.span`
  margin-right: 8px;
  font-size: 16px;
  color: ${textColor.white};
  ${media.mobile`
    font-size: 12px;
  `};
`;

const RemainTimeClock = styled.img`
  margin-right: 4px;
  width: 24px;
  height: 24px;
  ${media.mobile`
    width: 16px;
    height: 16px;
  `};
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const Username = styled.div`
  ${TextEllipsis};
  font-size: 16px;
  color: ${textColor.white};
  ${media.mobile`
    font-size: 12px;
  `};
`;

const DesktopAvatar = styled.div`
  margin-left: 2px;
  margin-right: 4px;
  flex: none;
  display: flex;
  align-items: center;
  ${media.tablet`
    display: none;
  `};
`;

const NonDesktopAvatar = styled.div`
  margin-left: 2px;
  margin-right: 4px;
  flex: none;
  display: none;
  align-items: center;
  ${media.tablet`
    display: flex;
  `};
`;

const UserStatusIndicatorWrapper = styled.div`
  margin-left: 4px;
  flex: none;
`;

const Metadatas = styled.div`
  grid-area: metadata;
  display: flex;
  align-items: center;
`;

const Metadata = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: ${textColor.white};
  opacity: 0.5;
  & + & {
    margin-left: 8px;
  }
  > img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    ${media.mobile`
      width: 16px;
      height: 16px;
    `};
  }
  ${media.mobile`
    font-size: 12px;
  `};
`;

const ViewersIcon = styled.img`
  margin-right: 4px;
`;

const ViewCount = styled.div`
  margin-right: 8px;
`;

const MetaIcon = styled.img`
  margin-right: 4px;
  height: 16px;
  width: auto;
`;

const MetaValue = styled.div`
  margin-right: 8px;
  font-weight: 400;
  :last-child {
    margin-right: unset;
  }
`;

const UnlockPrice = styled.div`
  grid-area: unlockPrice;
  text-align: right;
  > img {
    width: 16px;
    height: 16px;
    ${media.mobile`
      width: 12px;
      height: 12px;
    `};
  }
  > * {
    display: inline-block;
    vertical-align: middle;
  }
  ::after {
    content: '';
    width: 0;
    height: 100%;
    display: inline-block;
    position: relative;
    vertical-align: middle;
  }
`;

const UnlockPriceNumber = styled.div`
  color: #00d2be;
  font-size: 14px;
  font-weight: 600;
  margin-left: 2px;
  ${media.mobile`
    font-size: 12px;
  `};
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export default withTranslation()(OutboxMessage);
